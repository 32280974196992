import {NextNotification} from '../models/next-notification';

declare const window: any;

export class AppUtils {

  public static readonly WEEK_DAYS: string[] = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
  public static readonly MONTHS: string[] = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
  public static readonly TIME_TO_NOTIFY_PUSH: number = 604800; // 7 DAYS

  private constructor() {
  }

  public static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static deleteLoader(): void {
    setTimeout(() => document.getElementById('init-preloader')?.remove(), 0);
  }

  public static isPublicPath(path: string = ''): boolean {
    return path.indexOf('forgot') > 0 || path.indexOf('restore') > 0 || path.indexOf('info') > 0 || path.indexOf('faqs') > 0;
  }

  public static pick(object: Record<string, any>, keys: string[]): Record<string, any> {
    return ({...keys.reduce((res: {}, key: string) => ({...res, [key]: object[key]}), {})});
  }

  public static canShowNotificationPermission(nextNotification?: NextNotification): number {
    const value: number = nextNotification?.t || 0;

    if (value === 0) {
      return -1;
    }

    const now: number = Math.round(new Date().getTime() / 1000.0);
    const difference: number = now - value;

    if (difference > this.TIME_TO_NOTIFY_PUSH) {
      return -1;
    } else {
      return value + this.TIME_TO_NOTIFY_PUSH;
    }
  }

  public static getUrlParameter(query: string, name: string): string | undefined {
    if (query === '') {
      return undefined;
    }

    name = name.replace(/\[/, '\\[').replace(/]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results: RegExpExecArray | null = regex.exec(query);

    return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  public static baToStr(buffer: ArrayBuffer): string {
    const bufferArray: Uint8Array = new Uint8Array(buffer);

    // @ts-ignore
    return window.btoa(String.fromCharCode.apply(null, bufferArray))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

}
