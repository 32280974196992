import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {NgEventBus} from 'ng-event-bus';

import {StoreService} from './store.service';
import {AuthService} from './auth.service';
import {SyncService} from './sync.service';
import {LogRocketService} from './log-rocket.service';
import {ToastService} from './toast.service';
import {SessionService} from './session.service';
import {WebPushNotificationService} from './web-push-notification.service';
import {SessionDTO} from '../../../models/dto/session-dto';
import {BusEvents} from '../../../models/enums/bus-events';
import {ApiResponse} from '../../../models/api-response';
import {Auth} from '../../../models/auth';
import {AppLogger} from '../../../utils/app-logger';
import {AppUtils} from '../../../utils/app-utils';

import {AppComponent} from '../app.component';

declare const window: any;

@Injectable()
export class StartupService {

  private app!: AppComponent;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private syncService: SyncService,
              private logRocketService: LogRocketService,
              private toastService: ToastService,
              private sessionService: SessionService,
              private router: Router,
              private eventBus: NgEventBus,
              private webPushNotificationService: WebPushNotificationService) {
  }

  public checkSession(app: AppComponent): void {
    this.app = app;
    const path: string = String(window.location.pathname);

    if (AppUtils.isPublicPath(path)) {
      AppLogger.info('[Startup] Starting in public page, user session deleted!');
      this.storeService.deleteAuth();

      this.render();
      return;
    }

    if (!this.storeService.getAuth()) {
      AppLogger.info('[Startup] User session not found');
      this.redirectToLogin(path);
      return;
    }

    AppLogger.info('[Startup] Checking user session status...');

    this.authService.checkSession()
      .subscribe((r: ApiResponse<SessionDTO>) => {
        if (!r.success) {
          this.redirectToLogin(path);
          return;
        }

        this.setCurrentSession(path, r.data);
        this.eventBus.cast(BusEvents.APP_SESSION);

        setTimeout(() => {
          this.syncService.start();
          this.webPushNotificationService.start();
        }, 1000);
      });
  }

  private setCurrentSession(path: string, data: SessionDTO): void {
    // TODO: this.logRocketService.addUser(data.name, data.email);
    const auth: Auth | undefined = this.storeService.getAuth();

    if (auth) {
      this.storeService.setAuth({...auth});
    }

    AppLogger.info('[Startup] User session status OK!');

    if (path === '/' || path.startsWith('/login')) {
      this.router.navigateByUrl('home').finally(() => this.render());
    } else {
      this.render();
    }
  }

  private redirectToLogin(path: string): void {
    const isInLogin: boolean = !(path !== '/' && path.indexOf('login') < 0);

    if (isInLogin) {
      this.sessionService.logout(false);
      this.render();
    } else {
      this.sessionService.logout();
    }
  }

  private render(): void {
    if (this.app) {
      AppLogger.info('[Startup] Rendering app!');
      this.app.render = true;
      AppUtils.deleteLoader();
    }
  }

}
