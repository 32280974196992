import {Injectable} from '@angular/core';

import {LogUpdateService} from './log-update.service';
import {CheckForUpdateService} from './check-for-update.service';
import {AppLogger} from '../../../utils/app-logger';

@Injectable()
export class LoaderService {

  private started: boolean;

  constructor(private logUpdateService: LogUpdateService,
              private checkForUpdateService: CheckForUpdateService) {

    this.started = false;
  }

  public load(): void {

    if (this.started) {
      AppLogger.info('[Loader] Non-priority services for app already initialized');
      return;
    }

    AppLogger.info('[Loader] Initializing non-priority services for app...');

    this.logUpdateService.start();
    this.checkForUpdateService.start();

    this.started = true;
  }

}
