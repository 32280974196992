import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {env} from '../../../../env/env';

import {ApiService} from './api.service';
import {RefreshDTO} from '../../../models/dto/refresh-dto';
import {CredentialDTO} from '../../../models/dto/credential-dto';
import {AuthDTO} from '../../../models/dto/auth-dto';
import {SessionDTO} from '../../../models/dto/session-dto';
import {ApiResponse} from '../../../models/api-response';
import {ApiRoutes} from '../../../utils/api-routes';
import {ApiUtils} from '../../../utils/api-utils';

@Injectable()
export class AuthService {

  constructor(private apiService: ApiService) {
  }

  public checkSession(): Observable<ApiResponse<SessionDTO>> {
    return this.apiService.get(ApiRoutes.sessionUrl());
  }

  public login(credential: CredentialDTO): Observable<ApiResponse<AuthDTO>> {
    return this.apiService.post(ApiRoutes.loginUrl(), credential);
  }

  public refreshToken(refresh: RefreshDTO): Observable<ApiResponse<AuthDTO>> {
    return new Observable<ApiResponse<AuthDTO>>(observer => {
      const headers: HeadersInit = {
        'content-type': 'application/json',
        'ngsw-bypass': 'true',
        'x-web-version': env.version
      };

      const ctrl: AbortController = new AbortController();
      const id = setTimeout(() => ctrl.abort(), env.api.timeout);

      const init: RequestInit = {
        body: JSON.stringify(refresh),
        cache: 'no-cache',
        headers,
        keepalive: false,
        method: 'POST',
        signal: ctrl.signal
      };

      fetch(ApiRoutes.refreshUrl(), init)
        .then((r: Response) => ApiUtils.handleFetchResponse<AuthDTO>(r))
        .then((r: ApiResponse<AuthDTO>) => observer.next(r))
        .catch(() => observer.next(ApiUtils.defaultError<AuthDTO>()))
        .finally(() => {
          observer.complete();
          clearTimeout(id);
        });
    });
  }

}
