import {env} from '../../env/env';

export class ApiRoutes {

  private static readonly API_URL_V1: string = `${env.api.url}:${env.api.port}/${env.api.version}`;

  public static loginUrl(): string {
    return `${ApiRoutes.API_URL_V1}/auth/login`;
  }

  public static refreshUrl(): string {
    return `${ApiRoutes.API_URL_V1}/auth/refresh`;
  }

  public static sessionUrl(): string {
    return `${ApiRoutes.API_URL_V1}/auth/session`;
  }

  public static notificationsUrl(extras = ''): string {
    return `${ApiRoutes.API_URL_V1}/notifications${extras !== '' ? '/' + extras : ''}`;
  }

  public static devicesUrl(id = ''): string {
    return `${ApiRoutes.API_URL_V1}/devices${id !== '' ? '/' + id : ''}`;
  }

}
