import {Component} from '@angular/core';

import {Message} from 'primeng/api';
import {ToastCloseEvent} from 'primeng/toast';
import {NgEventBus} from 'ng-event-bus';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  constructor(private eventBus: NgEventBus) {

  }


  public closeWithTimeout($event: ToastCloseEvent): void {
    console.log('close', $event)
  }

  public action1($event: Event, message: Message): void {
    console.log('action1')
  }

  public action2($event: Event, message: Message): void {
    console.log('action2')
  }

  public closeWithX($event: Event, message: Message): void {
    console.log('closeX', $event, message)
  }
}
