export class ApiErrorMessages {

  private static readonly ERRORS: Record<string, string> = {

  };

  public static getErrorText(code: number): string {
    const errorCode = String(code);
    return ApiErrorMessages.ERRORS[errorCode] || ApiErrorMessages.ERRORS['1'];
  }

  public static getServerFields(): string[] {
    return [
      'users_email_unique',
      'name',
      'email',
      'assigned_to',
      'property_field_id_value',
      'users.email'
    ];
  }

  public static getFieldNames(): string[] {
    return [
      'Correo Electrónico',
      'Nombre',
      'Correo Electrónico',
      'Asignado',
      'Opción',
      'Correo Electrónico'
    ];
  }

}
