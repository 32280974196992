import {Injectable} from '@angular/core';

import {StoreService} from './store.service';
import {Auth} from '../../../models/auth';

@Injectable()
export class LogRocketService {

  constructor(private storeService: StoreService) {
  }

  public addUser(name: string, email: string): void {
    const auth: Auth | undefined = this.storeService.getAuth();

    // TODO: const id: string = auth?.id || '';
    // const scope: UserScope = auth?.sc || UserScope.NORMAL;
    //
    // if (env.app.activeLogRocket) {
    //   if (window.LogRocket && id !== '') {
    //     window?.LogRocket?.identify(id, {
    //       name,
    //       email,
    //       Scope: AppUtils.capitalizeFirst(scope.toString().replace('_', ' ')),
    //       Version: env.version
    //     });
    //   } else {
    //     AppLogger.warn('[LogRocket] Cannot read a proper user ID and/or scope: ', id, scope);
    //   }
    // } else {
    //   AppLogger.warn('[LogRocket] LogRocket is only enabled in production environment, aborting identify user!')
    // }
  }

}
