import {Injectable} from '@angular/core';

@Injectable()
export class ModalService {

  public open(component: any, data: any = {}): void {
    // TODO: Validate modals in app
  }

}
