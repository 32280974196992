export enum BusEvents {
  APP_ALL = '**',

  APP_SESSION = 'APP:SESSION',
  APP_SESSION_EXPIRED = 'APP:SESSION_EXPIRED',
  APP_WEB_PUSH = 'APP:WEB_PUSH',

  RT_API = 'RT:API',
  RT_APP = 'RT:APP',
  RT_PUSH_DEVICES = 'RT:PUSH_DEVICES'
}
