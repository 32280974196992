import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    title: 'Iniciar sesión | SIGEFI Portal',
    loadChildren: () => import('./../login/login.module').then(m => m.LoginModule)
  },
  {
    path: '404',
    title: 'Página no encontrada | SIGEFI Portal',
    loadChildren: () => import('./../not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '**',
    title: 'Página no encontrada | SIGEFI Portal',
    loadChildren: () => import('./../not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        useHash: false,
        preloadingStrategy: NoPreloading,
        onSameUrlNavigation: 'ignore',
        scrollPositionRestoration: 'enabled'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
