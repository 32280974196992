import {StoreKeys} from '../models/enums/store-keys';
import {AppUtils} from './app-utils';
import {AppLogger} from './app-logger';

export class AppStore {

  private static readonly KEY: string = '@;#';
  private static store: Record<string, any> = {};
  private static readonly keysToSave: string[] = [
    StoreKeys.AUTH,
    StoreKeys.INSTALLATION,
    StoreKeys.NOTIFICATION_TIME,
    StoreKeys.VERSION
  ].map(x => x.toString());

  public static loadStore(): void {
    const value: string | null = localStorage.getItem(AppStore.KEY);
    let data: Record<string, any> = {};

    if (value) {
      try {
        data = JSON.parse(window.atob(value));
      } catch (e) {
        AppLogger.error('[AppStore] Error parsing store', e);
        data = {};
      }
    }

    AppLogger.info('[AppStore] Store loaded!');
    AppStore.store = data;
  }

  private static putStorage(): void {
    setTimeout(() => {
      const data: Record<string, any> = AppUtils.pick(AppStore.store, AppStore.keysToSave);
      const base64: string = window.btoa(JSON.stringify(data));
      localStorage.setItem(AppStore.KEY, base64);
    }, 10);
  }

  private static update<T>(key: StoreKeys, data: T): void {
    AppStore.store = {...AppStore.store, [key]: {...data}};
    AppStore.putStorage();
  }

  private static remove(key: StoreKeys): void {
    AppStore.store = {...AppStore.store, [key]: undefined};
    AppStore.putStorage();
  }

  private static read<T>(key: StoreKeys): T | undefined {
    return AppStore.store[key];
  }

  public static delete(key: StoreKeys): void {
    AppStore.remove(key);
  }

  public static set<T>(key: StoreKeys, data: T): void {
    AppStore.update<T>(key, data);
  }

  public static get<T>(key: StoreKeys): T | undefined {
    return AppStore.read<T>(key);
  }

}
