import {Injectable} from '@angular/core';

import {AlertComponent} from '../components/alert/alert.component';

import {ModalService} from './modal.service';
import {AlertDTO} from '../../../models/dto/alert-dto';

@Injectable()
export class AlertService {

  constructor(private modalService: ModalService) {

  }

  public showAlert(alert: AlertDTO): void {
    this.modalService.open(AlertComponent, {alert});
  }

}
