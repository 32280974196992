import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {ApiService} from './api.service';
import {PushDeviceCreateDTO} from '../../../models/dto/push-device-create-dto';
import {NotificationClickDTO} from '../../../models/dto/notification-click-dto';
import {PushDeviceDTO} from '../../../models/dto/push-device-dto';
import {ApiResponse} from '../../../models/api-response';
import {ApiRoutes} from '../../../utils/api-routes';

@Injectable()
export class WebPushService {

  constructor(private apiService: ApiService) {

  }

  public registerDevice(data: PushDeviceCreateDTO): Observable<ApiResponse<PushDeviceDTO>> {
    return this.apiService.post(ApiRoutes.devicesUrl(), data);
  }

  public registerClick(notificationId: string, data: NotificationClickDTO): Observable<ApiResponse<string>> {
    return this.apiService.put(ApiRoutes.notificationsUrl(notificationId), data);
  }

}
