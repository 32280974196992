import {HttpErrorResponse} from '@angular/common/http';

import {ApiErrorMessages} from '../models/api-error-messages';
import {ApiResponse} from '../models/api-response';
import {ApiStatus} from '../models/api-status';
import {AppLogger} from './app-logger';

export class ApiUtils {

  private constructor() {
  }

  public static handleAPIError<T>(httpErrorResponse: HttpErrorResponse): ApiResponse<T> {
    const error = httpErrorResponse?.error || httpErrorResponse;
    const apiStatus: ApiStatus = ApiUtils.getApiStatus(error);

    const r: ApiResponse<T> = {
      success: false,
      totalRecords: 0,
      data: null as T,
      error: {
        fromApi: (httpErrorResponse?.status || -1) > 0,
        httpCode: httpErrorResponse?.status || -1,
        httpText: httpErrorResponse?.statusText || '',
        api: apiStatus
      }
    };

    AppLogger.error('[API] Error', r.error);

    return r;
  }

  private static getApiStatus(error?: any): ApiStatus {
    if (error?.constructor.name === 'TimeoutErrorImpl') {
      return {code: 0, message: ApiErrorMessages.getErrorText(0)};
    }

    const code = Number(error?.error?.code || 1);
    const message: string = ApiErrorMessages.getErrorText(code);

    if (code === 5 || code === 6) {
      return {code, message: ApiUtils.getMessageForConflict(code, error.error.message)};
    } else {
      return {code, message};
    }
  }

  private static getMessageForConflict(code: number, message: string): string {
    let position = 0;
    let fieldName: string;

    if (ApiErrorMessages.getServerFields().some((element: string, index: number) => {
      if (message.indexOf(element) >= 0) {
        position = index;
        return true;
      } else {
        return false;
      }
    })) {
      fieldName = ApiErrorMessages.getFieldNames()[position];
    } else {
      return 'Hubo un error al realizar la operación. Intenta nuevamente.';
    }

    return ApiErrorMessages.getErrorText(code).replace('{{field}}', fieldName);
  }

  public static async handleFetchResponse<T>(r: Response): Promise<ApiResponse<T>> {
    let fromApi = true;
    let code = 1;
    let message: string = ApiErrorMessages.getErrorText(code);

    try {
      if (r.ok) {
        const json = await r.json();
        return {...json};
      } else {
        const json = await r.json();

        code = json?.error?.code || 1;
        message = ApiErrorMessages.getErrorText(code);
      }
    } catch (e) {
      fromApi = false;
    }

    const re: ApiResponse<T> = {
      success: false,
      totalRecords: 0,
      data: null as T,
      error: {
        fromApi,
        httpCode: r.status,
        httpText: r.statusText,
        api: {code, message}
      }
    };

    AppLogger.error('[FETCH] Error', re.error);

    return re;
  }

  public static defaultError<T>(): ApiResponse<T> {
    const code = 1;
    const message: string = ApiErrorMessages.getErrorText(code);

    return {
      success: false,
      totalRecords: 0,
      data: null as T,
      error: {
        fromApi: false,
        httpCode: -1,
        httpText: '',
        api: {code, message}
      }
    };
  }

}
