import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {NgEventBus} from 'ng-event-bus';

import {env} from '../../../env/env';

import {AppRoutingModule} from './app-routing.module';

import {AvatarModule} from 'primeng/avatar';
import {ButtonModule} from 'primeng/button';
import {StyleClassModule} from 'primeng/styleclass';
import {ToastModule} from 'primeng/toast';

import {MessageService} from 'primeng/api';

import {AlertService} from './services/alert.service';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {CheckForUpdateService} from './services/check-for-update.service';
import {LoaderService} from './services/loader.service';
import {LogRocketService} from './services/log-rocket.service';
import {LogUpdateService} from './services/log-update.service';
import {ModalService} from './services/modal.service';
import {SessionService} from './services/session.service';
import {StartupService} from './services/startup.service';
import {StoreService} from './services/store.service';
import {SyncService} from './services/sync.service';
import {ToastService} from './services/toast.service';
import {WebPushNotificationService} from './services/web-push-notification.service';
import {WebPushService} from './services/web-push.service';

import {AlertComponent} from './components/alert/alert.component';
import {AppComponent} from './app.component';
import {ToastComponent} from './components/toast/toast.component';

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    ToastComponent
  ],
  imports: [
    AppRoutingModule,
    AvatarModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    HttpClientModule,
    ServiceWorkerModule.register('sw.js', {
      enabled: env.app.activeSW,
      scope: './',
      registrationStrategy: 'registerImmediately'
    }),
    StyleClassModule,
    ToastModule
  ],
  providers: [
    AlertService,
    ApiService,
    AuthService,
    CheckForUpdateService,
    NgEventBus,
    LoaderService,
    LogRocketService,
    LogUpdateService,
    MessageService,
    ModalService,
    SessionService,
    StartupService,
    StoreService,
    SyncService,
    ToastService,
    WebPushNotificationService,
    WebPushService,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
