import {Component, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {MetaData, NgEventBus} from 'ng-event-bus';
import { PrimeNGConfig } from 'primeng/api';

import {env} from '../../../env/env';

import {StartupService} from './services/startup.service';
import {StoreService} from './services/store.service';
import {LoaderService} from './services/loader.service';
import {BusEvents} from '../../models/enums/bus-events';
import {AppLogger} from '../../utils/app-logger';
import {AppUtils} from '../../utils/app-utils';
import {ToastService} from "./services/toast.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  private sessionSub!: Subscription;
  private expiredSessionSub!: Subscription;

  public render = false;
  public expiredSession = false;

  constructor(private startupService: StartupService,
              private storeService: StoreService,
              private loaderService: LoaderService,
              private eventBus: NgEventBus,
              private primengConfig: PrimeNGConfig) {
  }

  public ngOnInit(): void {
    this.primengConfig.ripple = true;

    if (env.app.activeEventBusLogger) {
      this.eventBus.on(BusEvents.APP_ALL).subscribe((value: MetaData) => AppLogger.info('[App] Event bus:', value.key));
    }

    this.sessionSub = this.eventBus.on(BusEvents.APP_SESSION)
      .subscribe(() => {
        this.activateSessionListener();
        this.sessionSub?.unsubscribe();
      })

    this.startupService.checkSession(this);
    this.loaderService.load();
  }

  private activateSessionListener(): void {
    this.expiredSessionSub = this.eventBus.on(BusEvents.APP_SESSION_EXPIRED)
      .subscribe(() => {
        this.renderSession();
        this.expiredSessionSub?.unsubscribe();
      });
  }

  private renderSession(): void {
    AppLogger.info('[App] Rendering session error!');
    this.render = true;
    this.expiredSession = true;
    AppUtils.deleteLoader();
  }
}
