<p-toast position="bottom-right" styleClass="p-toast-sig" (onClose)="closeWithTimeout($event)" >
  <ng-template let-message pTemplate="headless">
    <section class="flex flex flex-column p-0 gap-0 w-full shadow-1 bg-white">
      <div class="toast-header">
        {{ message.summary }} <i class="pi pi-times toast-close" (click)="closeWithX($event, message)"></i>
      </div>
      <div class="toast-body" [innerHTML]='message.detail'></div>
      <div class="toast-buttons" *ngIf="message.data.action1 || message.data.action2">
        <p-button [label]="message.data.action1"  [raised]="true" size="small" (click)="action1($event, message)" *ngIf="message.data.action1"/>
        <p-button [label]="message.data.action2" [raised]="true" size="small" [outlined]="true" (click)="action2($event, message)" *ngIf="message.data.action2"/>
      </div>
    </section>
  </ng-template>
</p-toast>
