import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {NgEventBus} from 'ng-event-bus';

import {StoreService} from './store.service';
import {LogRocketService} from './log-rocket.service';
import {WebPushNotificationService} from './web-push-notification.service';
import {AuthDTO} from '../../../models/dto/auth-dto';
import {BusEvents} from '../../../models/enums/bus-events';
import {ApiResponse} from '../../../models/api-response';
import {Auth} from '../../../models/auth';
import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class SessionService {

  constructor(private storeService: StoreService,
              private webPushNotificationService: WebPushNotificationService,
              private logRocketService: LogRocketService,
              private eventBus: NgEventBus,
              private router: Router) {

  }

  public login(r: ApiResponse<AuthDTO>, event: string): void {
    const auth: Auth = {
      id: r.data.id,
      // TODO: Add auth data
    };

    window.umami?.track(event);

    this.storeService.setAuth(auth);
    this.webPushNotificationService.start();
    // TODO: this.logRocketService.addUser(r.data.name, r.data.email);
    this.eventBus.cast(BusEvents.APP_SESSION);

    this.router.navigateByUrl('home').catch(r => AppLogger.error('[Session] Navigation error', r));
    AppLogger.info('[Session] User session started!');
  }

  public logout(redirect = true): void {
    this.storeService.deleteAuth();
    AppLogger.info('[Session] User session ended!');

    if (redirect) {
      setTimeout(() => window.location.href = `${window.location.protocol}//${window.location.host}/login`, 100);
    }
  }

}
