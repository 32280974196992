declare const window: any;

export class AppLogger {

  public static debug(...args: any): void {
    console.debug(...args);
  }

  public static info(...args: any): void {
    console.log(...args);
  }

  public static warn(...args: any): void {
    console.warn(...args);
  }

  public static error(...args: any): void {
    console.error(...args);
    window?.Rollbar?.error(...args);
  }

  public static errorFromHandler(...args: any): void {
    console.error('[ErrorHandlerService]', ...args);
  }

}
